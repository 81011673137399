<template>
<el-container class="ib">
    <el-aside width="auto">
      <ib-menu></ib-menu>
    </el-aside>
    <div style="width: 100%; overflow: auto;">
      <el-main class="main-cnt" style="height: 100%">
        <router-view></router-view>
      </el-main>
    </div>
  </el-container>
</template>

<script>
import ibMenu from '@/components/leftMenu/Index.vue';

  export default {
    components: {
      ibMenu,
    },
  };
</script>

<style lang="less">
  .el-container{
    height: 100%;
    overflow: hidden;
  }
</style>
